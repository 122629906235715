.wishes {

  height: 100vh;
  position: absolute;
  right: 10px;
  top: 0;
  width: 48%;
  display: inline-flex;
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: inherit;
  z-index: 100;

  .wishes-container {
    min-width: 150px;
    margin-bottom: 0;
    padding-bottom: $snowBottom * 2;
    position: absolute;
    top: calc(100vh - 200px - $snowBottom);
    right: 10px;
    max-width: 300px;
    left: 10px;
    min-height: 300px;
    background: linear-gradient(0deg, #66272a 80%, transparent 80%);

    @media all and (min-height: $max-height) {
      top: $max-height - 300px + $snowBottom;
    }


    .title {
      display: flex;
      position: relative;
      background: linear-gradient(8deg, #66272a 80%, transparent 20%);
      padding-top: 140px;
      padding-bottom: 15px;
      font-family: 'Charm', serif;
      font-size: 28px;
      font-weight: 700;

      [class^=ribbon-] {
        position: relative;
        margin-bottom: 80px;
      }

      [class^=ribbon-]:before, [class^=ribbon-]:after {
        content: "";
        position: absolute;
      }

      .ribbon {
        width: calc(100% + 20px);
        height: 50px;
        margin-left: -10px;
        margin-right: -10px;
        background: #efb23b;
        color: $darkblue;
      }

      .ribbon:before {
        height: 0;
        width: 0;
        border-top: 10px solid #cd8d11;
        border-left: 10px solid transparent;
        bottom: -10px;
      }

      .ribbon:after {
        height: 0;
        width: 0;
        border-top: 10px solid #cd8d11;
        border-right: 10px solid transparent;
        right: 0;
        bottom: -10px;
      }

      .ribbon:before {
        height: 0;
        width: 0;
        border-width: 20px 20px;
        border-style: solid;
        border-color: #31a5b3 #31a5b3 #31a5b3 transparent;
        top: 20px;
        left: -30px;
      }

      .ribbon:after {
        height: 0;
        width: 0;
        border-width: 20px 20px;
        border-style: solid;
        border-color: #31a5b3 transparent #31a5b3 #31a5b3;
        top: 20px;
        right: -30px;
      }

      .ribbon-content {
        height: inherit;
        margin-bottom: 0;
        background: #fef1a5;
        z-index: 100;
        display: flex;

        span {
          margin: auto
        }
      }

      .ribbon-content:before {
        height: 0;
        width: 0;
        border-top: 10px solid #a39969;
        border-left: 10px solid transparent;
        bottom: -10px;
      }

      .ribbon-content:after {
        height: 0;
        width: 0;
        border-top: 10px solid #a39969;
        border-right: 10px solid transparent;
        right: 0;
        bottom: -10px;
      }

    }

    .user-wishes {
      padding: 15px;
      background: #66272a;

      .card {
        position: relative;
        margin: 15px 0;
        padding: 5px;
        border: 5px solid #fff0a5;
        border-radius: 5px;
        background: #eaeaea;
        color: #162a4e;

        .description {
          white-space: pre-line;
          font-size: 17px;
          margin-bottom: 20px;
        }

        .link {
          display: block;
          text-align: center;
          margin: 15px auto;
          background: #fef1a5;
          width: fit-content;
          padding: 5px 15px;
          border-radius: 5px;
          border: 2px solid #66272a;
          color: #66272a;
          text-decoration: none;
          font-size: 22px;
          font-weight: 700;
          font-family: "Charm", serif;
        }

        .button {
          display: flex;
          padding: 5px;
          position: absolute;
          top: -15px;
          cursor: pointer;
          align-content: center;
          justify-content: center;
          color: #66272a;
          border: 5px solid #fff0a5;
          border-radius: 50%;
          width: 35px;
          height: 35px;
          background: white;

          &.edit {
            right: -15px;
          }

          &.delete {
            left: -15px;
          }
        }

      }

      .content {
        margin: 5px;
      }

      h2 {
        text-align: center;
        font-size: 19px;
        margin-top: 10px;
        margin-bottom: 20px;
        overflow-wrap: break-word;
      }
    }

    .add-wish {
      position: absolute;
      right: -10px;
      font-size: 13px;
      bottom: $snowBottom + 50px;
      display: flex;
      flex-direction: column;
      cursor: pointer;
      color: $red;

      @include breakpoint($breakpoint-tablet) {
        right: -25px;
        font-size: 16px;
      }


      .icon {
        display: none;
        margin-left: auto;
        border: 5px solid #66272a;
        outline: 10px solid #162a4e;
        border-radius: 50%;
        width: 35px;
        height: 35px;
        background: white;
        cursor: pointer;
        color: #66272a;
        text-align: center;
      }

      .description {
        padding: 5px;
        background: #eaeaea;
        border: 2px solid #fff0a5;
        border-radius: 5px;
        outline: 10px solid #162a4e;
        position: relative;
        font-weight: 600;
      }
    }
  }

}