$gold: #ffd993;
$silver: #f5f5f0;
$lightblue: #253854;
$darkblue: #162a4e;
$red: #66272a;
$yellow: #fef1a5;

$breakpoint-tablet: 750px;
$max-height: 1400px;
$snowBottom: 65px;

@mixin breakpoint($min-width, $max-width: false) {
  @if ($max-width) {
    @media all and (min-width: $min-width) and (max-width: $max-width) {
      @content;
    }
  } @else {
    @media all and (min-width: $min-width) {
      @content;
    }
  }
}


@font-face {
  font-family: "Great Vibes";
  src: url(./Fonts/Great_Vibes/GreatVibes-Regular.ttf);
}

#root {
  width: 100vw;
  height: 100vh;




    @media all and (min-height: $max-height) {
      &:after {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        background: white;
        z-index: 110;
        //height: 50px;
        height: calc(100vh - $max-height + $snowBottom);
      }
    }

}

main {
  max-width: 700px;
  max-height: $max-height;
  margin: auto;
  position: fixed;
  width: 100vw;
  height: 100vh;

  @include breakpoint($breakpoint-tablet) {
    position: relative;
  }

  .snow-ground {
    display: none;

    &.bottom {
      bottom: 0;
      left: 0;
      position: fixed;
      width: 100vw;
      z-index: 100;
      height: $snowBottom;
      background: white;
      --mask: radial-gradient(111.80px at 50% 150.00px, #000 99%, #0000 101%) calc(50% - 100px) 0/200px 100%, radial-gradient(111.80px at 50% -100px, #0000 99%, #000 101%) 50% 50px/200px 100% repeat-x;
      -webkit-mask: var(--mask);
      mask: var(--mask);

      &.middle {
        opacity: 0.5;
        width: 110vw;
        transform: translateX(-10px);
        z-index: -1;
      }

      &.behind {
        opacity: 0.25;
        width: 110vw;
        transform: translateX(-35px);
        z-index: -1;
      }

    }
  }


}

@import "./login.scss";
@import "./wichtelsite";
