.target {
  position: absolute;
  top: 0;
  left: 10px;
  width: 45%;
  height: 100vh;
  display: inline-flex;
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: inherit;


  .target-container {
    background: linear-gradient(0deg, $red 80%, transparent 20%);
    min-width: 150px;
    margin-bottom: 0;
    padding: 0 5px;
    padding-bottom: $snowBottom;
    position: absolute;
    left: 10px;
    right: 10px;
    top: calc(100vh - 310px);
    min-height: 300px;
    z-index: 100;

    @media all and (min-height: $max-height) {
      top: $max-height - 300px;
    }


    .target-header {
      background: linear-gradient(-8deg, $red 74%, transparent 26%);
      margin: -5px;
      min-height: 175px;
      display: flex;
      @import "santa";
    }

    .title {
      display: flex;
      position: relative;
      padding-top: 30px;
      padding-bottom: 15px;
      font-family: 'Charm', serif;
      font-size: 28px;
      font-weight: 700;
      background: $red;
      margin-left: -5px;
      margin-right: -5px;

      [class^=ribbon-] {
        position: relative;
        margin-bottom: 80px;
      }

      [class^=ribbon-]:before, [class^=ribbon-]:after {
        content: "";
        position: absolute;
      }

      .ribbon {
        width: calc(100% + 30px);
        height: 50px;
        margin-left: -10px;
        margin-right: -10px;
        color: $darkblue;
      }

      .ribbon:before {
        height: 0;
        width: 0;
        border-top: 10px solid #cd8d11;
        border-left: 10px solid transparent;
        bottom: -10px;
      }

      .ribbon:after {
        height: 0;
        width: 0;
        border-top: 10px solid #cd8d11;
        border-right: 10px solid transparent;
        right: 0;
        bottom: -10px;
      }

      .ribbon:before {
        height: 0;
        width: 0;
        border-width: 20px 20px;
        border-style: solid;
        border-color: #31a5b3 #31a5b3 #31a5b3 transparent;
        top: 20px;
        left: -30px;
      }

      .ribbon:after {
        height: 0;
        width: 0;
        border-width: 20px 20px;
        border-style: solid;
        border-color: #31a5b3 transparent #31a5b3 #31a5b3;
        top: 20px;
        right: -30px;
      }

      .ribbon-content {
        height: inherit;
        margin-bottom: 0;
        background: #fef1a5;
        z-index: 100;
        display: flex;

        span {
          margin: auto
        }
      }

      .ribbon-content:before {
        height: 0;
        width: 0;
        border-top: 10px solid #a39969;
        border-left: 10px solid transparent;
        bottom: -10px;
      }

      .ribbon-content:after {
        height: 0;
        width: 0;
        border-top: 10px solid #a39969;
        border-right: 10px solid transparent;
        right: 0;
        bottom: -10px;
      }

    }

    .target-title {
      display: flex;
      text-align: center;
      background: #fef1a5;
      border-radius: 5px;
      color: transparent;
      transition: color 0.5s linear;
      font-family: 'Oswald', sans-serif;

      &:before {
        content: "Klick mich!";
        position: absolute;
        width: 100%;
        color: $darkblue;
        text-align: center;
        font-size: 24px;
        letter-spacing: 2px;
        padding-top: 15px;
        transition: color 0.5s linear;
      }

      &.show {
        color: $darkblue;

        &:before {
          color: transparent;
        }

      }

      h2 {
        margin: 15px auto;
        font-size: 24px;
        letter-spacing: 2px;
      }
    }

    .target-wishes {
      padding: 15px;
      background: #66272a;
      transition: opacity 0.5s linear;

      .card {
        margin: 15px 0;
        padding: 5px;
        border: 5px solid #fff0a5;
        border-radius: 5px;
        background: #eaeaea;
        color: #162a4e;
      }

      .content {
        margin: 5px;
      }

      h2 {
        font-size: 18px;
        text-align: center;
        margin-top: 10px;
        margin-bottom: 20px;
        overflow-wrap: break-word;
      }

    }


  }

}