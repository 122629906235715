.delete-wish-notice {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  background: #d7d7d75e;
  z-index: 200;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;

  &-container {
    padding: 15px;
    border: 5px solid $yellow;
    border-radius: 5px;
    display: flex;
    background: $red;
    flex-direction: column;
    max-width: 95vw;
    text-align: center;

    .notice {

      padding: 15px;
      font-weight: 700;
      font-size: 1.5rem;

    }
    .controls {
      display: flex;
      justify-content: space-between;

      .button {
        border: 2px solid #fef1a5;
        padding: 5px;
        border-radius: 5px;
        cursor: pointer;
        background: white;
        font-weight: 700;
        color: #65272a;
      }
    }

    .card {
      padding: 15px;
      background: #66272a;
      border-radius: 5px;
      border: 5px solid #fef1a5;
      color: white;
      display: flex;
      flex-direction: column;
      min-width: 300px;

      > * {
        margin: 15px 0;
      }
    }
  }

}