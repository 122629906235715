.add-new-wish {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  background: #d7d7d75e;
  z-index: 200;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .title {
    display: flex;
    position: relative;
    color: black;

    [class^=ribbon-] {
      position: relative;
      margin-bottom: 80px;
    }

    [class^=ribbon-]:before, [class^=ribbon-]:after {
      content: "";
      position: absolute;
    }

    .ribbon {
      width: calc(100% + 60px);
      height: 50px;
      margin-left: -30px;
      margin-right: -30px;
      font-family: "Charm", serif;
      font-size: 23px;
      font-weight: 700;
    }

    .ribbon:before {
      height: 0;
      width: 0;
      border-top: 10px solid #cd8d11;
      border-left: 10px solid transparent;
      bottom: -10px;
    }

    .ribbon:after {
      height: 0;
      width: 0;
      border-top: 10px solid #cd8d11;
      border-right: 10px solid transparent;
      right: 0;
      bottom: -10px;
    }

    .ribbon:before {
      height: 0;
      width: 0;
      border-width: 20px 20px;
      border-style: solid;
      border-color: #31a5b3 #31a5b3 #31a5b3 transparent;
      top: 20px;
      left: -30px;
    }

    .ribbon:after {
      height: 0;
      width: 0;
      border-width: 20px 20px;
      border-style: solid;
      border-color: #31a5b3 transparent #31a5b3 #31a5b3;
      top: 20px;
      right: -30px;
    }

    .ribbon-content {
      height: inherit;
      margin-bottom: 0;
      background: #fef1a5;
      z-index: 100;
      display: flex;

      span {
        margin: auto
      }
    }

    .ribbon-content:before {
      height: 0;
      width: 0;
      border-top: 10px solid #a39969;
      border-left: 10px solid transparent;
      bottom: -10px;
    }

    .ribbon-content:after {
      height: 0;
      width: 0;
      border-top: 10px solid #a39969;
      border-right: 10px solid transparent;
      right: 0;
      bottom: -10px;
    }

  }


  .card {
    padding: 15px;
    background: #66272a;
    border-radius: 5px;
    border: 5px solid #fef1a5;
    color: white;
    display: flex;
    flex-direction: column;
    min-width: 300px;
    max-width: 90vw;
    font-size: 16px;
    font-family: 'Roboto', sans-serif;

    * {
      font-size: inherit;
      font-family: inherit;
    }

    label {
      margin-top: 25px;
    }

    input, textarea {
      margin-top: 5px;
      padding: 5px 10px;
    }

    .controls {
      display: flex;
      justify-content: space-between;
      margin: 25px 0;

      .button {
        border: 2px solid #fef1a5;
        padding: 5px;
        border-radius: 5px;
        cursor: pointer;
        background: white;
        font-weight: 700;
        font-size: 18px;
        color: #65272a;
      }
    }
  }

}